header {
	.header-top{
		background-color: $dark-gray;
		color: $white;
		font-size: 16px;
		font-weight: 300;
		padding: 20px 0;
		.split{
			justify-content: space-between;
			align-items: center;
			gap: 20px;
			.wrapper{
				display: flex;
				gap: 20px;
				a{
					color: $white;
					display: flex;
					align-items: center;
					gap: 5px;
					i{
						font-size: 10px;
					}
					@include hover-focus{
						color: $orange;
					}
				}
			}
		}
	}
	.header-mid{
		padding: 27px 0 10px;
		.split{
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.side{
				flex: 1;
			}
			.menu-trigger{
				background-color: $orange;
				border: solid 1px $orange;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				.bars{
					width: 16px;
					height: 14px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					span{
						display: block;
						width: 100%;
						height: 1.5px;
						background-color: $white;
						transition: all .4s;
					}
				}
				@include hover-focus{
					background-color: $orange-hover;
				}
				&.active{
					.bars{
						position: relative;
						span{
							position: absolute;
							&:nth-child(2){
								top: calc(50% - 1px);
								transform: rotate(45deg);
							}
							&:nth-child(3){
								top: calc(50% - 1px);
								transform: rotate(-45deg);
							}
							&:nth-child(1){
								opacity: 0;
							}
							&:nth-child(4){
								opacity: 0;
							}
						}
					}
				}
			}
			.header-logo{
				img{
					display: block;
					margin: 0 auto;
					height: 19px;
				}
			}
			.buttons-row{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 10px;
				.icon-button{
					font-size: 20px;
					position: relative;
					padding: 0;
					.label-count{
						position: absolute;
						width: 12px;
						height: 12px;
						border-radius: 12px;
						background-color: $orange;
						font-size: 7px;
						color: $white;
						font-weight: 500;
						display: flex;
						justify-content: center;
						align-items: center;
						top: -8px;
						right: -5px;
					}
					&:last-child{
						padding-left: 5px;
					}
				}
			}
		}
		@include tablet-up{
			padding: 37px 0;
			.split{
				.side{
					flex: unset;
					.header-logo{
						img{
							height: 40px;
						}
					}
					&:last-child{
						display: flex;
					}
					.form-search{
						border: solid 1px #F0F0F0;
						padding: 3px;
						input{
							font-size: 20px;
							font-weight: 300;
							padding: 20px 40px;
							border: none;
							@include hover-focus{
								outline: none;
							}
						}
						button{
							background-color: $dark-gray;
							border: solid 1px $dark-gray;
							font-size: 24px;
							color: $white;
							padding: 18px;
							@include hover-focus{
								color: $orange;
							}
						}
					}
					.row{
						border: solid 1px #F0F0F0;
						padding: 3px 20px 3px 3px;
						display: flex;
						justify-content: space-between;
						gap: 30px;
						.categories{
							position: relative;
							width: 275px;
							>button{
								background-color: transparent;
								padding: 0;
								border: none;
								font-size: 20px;
								font-weight: 300;
								padding: 20px 40px 20px 15px;
								border: none;
								display: flex;
								gap: 10px;
								align-items: center;
								i{
									transition: all .4s;
									font-size: 16px;
								}
								&.active{
									i{
										transform: rotate(180deg);
									}
								}
								@include hover-focus{
									color: $orange;
								}
							}
							.wrapper{
								display: flex;
								flex-direction: column;
								position: absolute;
								background-color: $white;
								padding: 0 20px;
								width: 100%;
								border-radius: 0 0 5px 5px;
								top: 64px;
								left: -3px;
								transition: .4s all;
								pointer-events: none;
								opacity: 0;
								box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.1);
								&.active{
									top: 70px;
									pointer-events: all;
									opacity: 1;
								}
								button{
									width: 100%;
									text-align: left;
									background-color: transparent;
									border: none;
									border-bottom: solid 1px #D9D9D9;
									display: block;
									padding: 20px;
									font-size: 20px;
									font-weight: 300;
									@include hover-focus{
										color: $orange;
									}
									&:last-child{
										border-bottom: none;
									}
								}
							}
						}
						>.wrapper{
							display: flex;
							gap: 10px;
							align-items: center;
							font-size: 20px;
							a{
								@include hover-focus{
									color: $orange;
								}
							}
							.divider{
								background-color: #F0F0F0;
								height: 16px;
							}
						}
					}
					.buttons-row{
						padding-left: 35px;
						gap: 20px;
						.icon-button{
							font-size: 24px;
							@include hover-focus{
								color: $orange;
							}
							.label-count{
								width: 30px;
								height: 30px;
								font-size: 16px;
								border-radius: 30px;
								top: -20px;
								right: -20px;
							}
						}
					}
				}
			}
		}
	}
	.header-bottom{
		display: none;
		.categories-dropdown{
			background-color: $orange;
			color: $white;
			padding: 20px;
			font-size: 20px;
			font-weight: 500;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
			.fa-chevron-down{
				font-size: 16px;
			}
			.icon-list{
				font-size: 18px;
			}
			@include hover-focus{
				background-color: $orange-hover;
			}
		}
		.menu{
			ul{
				list-style: none;
				padding: 0;
				margin: 0;
				li{
					a{
						border-bottom: solid 1px $gray;
						padding: 20px;
						font-size: 20px;
						font-weight: 300;
						display: flex;
						justify-content: space-between;
						align-items: center;
						background-color: $white;
						i{
							font-size: 12px;
							transform: translateY(-1px);
						}
					}
				}
			}
		}
		.header-contact-us{
			background-color: $dark-gray;
			display: flex;
			align-items: center;
			gap: 20px;
			padding: 20px;
			p{
				font-size: 15px;
				color: $white;				
				font-weight: 300;
				text-transform: uppercase;
				line-height: 1.4;
				margin: 0;
				a{
					font-size: 20px;
					display: block;
					color: $white;
					font-weight: 500;				
				}
			}
		}
		@include tablet-up{
			display: block !important;
			background-color: $dark-gray;
			.split{
				width: 100%;
				align-items: center;
				.side{
					flex: 1;
					 &:nth-child(2){
						flex: 3;
					 }
					 .categories-dropdown{
						font-size: 24px;
						padding: 26px;
					 }
					 .menu{
						padding: 0 80px;
						ul{
							display: flex;
							width: 100%;
							li{
								flex: 1;
								a{
									font-size: 16px;
									padding: 24px;
									display: flex;
									gap: 10px;
									align-items: center;
									text-transform: uppercase;
									background-color: transparent;
									border-bottom: none;
									color: $white;
									justify-content: flex-start;
									@include hover-focus{
										color: $orange;
									}
								}
							}
						}
					 }
					 .header-contact-us{
							padding: 12px 20px;
							border-left: solid 1px $white;
							a{
								@include hover-focus{
									color: $orange;
								}
							}
					 }
				}
			}
		}
	}
}