@font-face {
  font-family: 'product-detail-purplefire';
  src: url('../../dist/font/product-detail-purplefire.eot?19419643');
  src: url('../../dist/font/product-detail-purplefire.eot?19419643#iefix') format('embedded-opentype'),
       url('../../dist/font/product-detail-purplefire.woff2?19419643') format('woff2'),
       url('../../dist/font/product-detail-purplefire.woff?19419643') format('woff'),
       url('../../dist/font/product-detail-purplefire.ttf?19419643') format('truetype'),
       url('../../dist/font/product-detail-purplefire.svg?19419643#product-detail-purplefire') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "product-detail-purplefire";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before { content: '\e800'; } /* '' */
.icon-youtube:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-heart-outline:before { content: '\e803'; } /* '' */
.icon-chevron-down:before { content: '\e804'; } /* '' */
.icon-list:before { content: '\e805'; } /* '' */
.icon-heart:before { content: '\e806'; } /* '' */
.icon-instagram:before { content: '\e807'; } /* '' */
.icon-minus:before { content: '\e808'; } /* '' */
.icon-pin:before { content: '\e809'; } /* '' */
.icon-phone:before { content: '\e80a'; } /* '' */
.icon-whatsapp:before { content: '\e80b'; } /* '' */
.icon-star:before { content: '\e80c'; } /* '' */
.icon-search:before { content: '\e80d'; } /* '' */
.icon-plus:before { content: '\e80e'; } /* '' */