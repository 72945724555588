.related-products-block{
  padding-bottom: 60px;
  h3{
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
  .swiper{
    padding-bottom: 60px;
  }
  .related-product{
    .wrapper{
      background-color: $lighter-gray;
      padding: 80px 20px 40px; 
      text-align: center;
      position: relative;
      margin-bottom: 30px;
      .fav{
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .btn{
        display: inline-block;
      } 
      img{
        margin: 30px 0 40px 0;
      }
    }
    &.label{
      .wrapper{
        &::before{
          content: "";
          background-color: transparent;
          font-size: 10px;
          border-radius: 20px;
          display: block;
          padding: 8px 20px;
          color: $white;
          font-weight: 500;
          max-width: max-content;
          position: absolute;
          top: 20px;
          left: 20px;
          text-transform: uppercase;
        }
      }
      &.label-new .wrapper{
        &::before{
          content: "New";
          background-color: #12A05C;
        }
      }
      &.label-10 .wrapper{
        &::before{
          content: "-10%";
          background-color: #E73C17;
        }
      }
      &.label-hot .wrapper{
        &::before{
          content: "Hot";
          background-color: #FF9900;
        }
      }
      &.label-warranty-2 .wrapper{
        &::before{
          content: "2 Years Warranty";
          background-color: #7F7CF6;
        }
      }
    }
    .title{
      text-align: center;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .price{
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      span{
        font-size: 12px;
        color: $gray;
        text-decoration: line-through;
      }
    }
  }
  @include tablet-up{
    padding: 60px 0;
    h3{
      font-size: 32px;
      margin-bottom: 60px;
    }
    .related-product{
      &.label{
        .wrapper{
          &::before{
            font-size: 13px;
          }
        }
      }
      .title,
      .price,
      .price span{
        font-size: 20px;
      }
      .title{
        margin-bottom: 10px;
      }
    }
  } 
}