footer {
  background-color: $dark-gray;
	color: $white;
	padding: 40px 0 20px;
	.split{
		gap: 30px;
	}
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
	}
  a{
    color: $white;
    @include hover-focus{
      color: $orange;
    }
  }
	h3{
		font-size: 10px;
    text-transform: uppercase;
		font-weight: 500;
		a{
			text-decoration: underline;
		}
	}
	.copyright{
		padding-top: 50px;
		text-align: center;
		font-size: 12px;
	}

  @include tablet-up{
		padding: 60px 0 90px;
    h3{
      font-size: 24px;			
			margin-bottom: 26px;
			a{
				text-decoration: none;
			}
    }
		.container{
			>.split{
				padding-bottom: 80px;
				>.side{
					flex: 1;
					p, ul{
						line-height: 30px;
					}
					.social{
						padding-top: 24px;
					}
					.split{
						padding-top: 16px;
						gap: 10px;
						align-items: center;
						.side{
							i{
								font-size: 30px;
							}
						}
					}
					&:last-child{
						p{
							a{
								color: $orange;
								@include hover-focus{
									text-decoration: underline;
								}
							}
						}
					}
					&:first-child,
					&:last-child{
						flex: 2;
					}
				}
			}
		}
		.copyright{
			font-size: 15px;
			position: relative;
			overflow: hidden;
			&::before{
				content: "";
				width: 100%;
				height: 1px;
				background-color: $white;
				display: block;
				position: absolute;
				top: 0;
				left: 50px;
			}
		}
  }
}