.newsletter-block{
  .content{
    padding: 40px 0;
    color: $white;
    h2{
      font-size: 18px;
      margin-bottom: 10px;
    }
    p{
      font-size: 14px;
      margin-bottom: 20px;
    }
    form{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      row-gap: 10px;
      input{
        flex: 2;
        padding: 20px;
        border: solid 1px $white;
        @include hover-focus{
          outline: none;
          border-color: $orange;
        }
      }
      button{
        flex: 1;
        padding: 20px 30px;
        text-transform: uppercase;
        @include hover-focus{
          background-color: $orange-hover;
        }
      }
      &.submitted{
        pointer-events: none;
        button{
          background-color: $black;
          border-color: $black;
        }
      }
    }
  }
  @include tablet-up{
    .content{
      h2{
        font-size: 28px;
      }
      p{
        font-size: 20px;
        margin-bottom: 0;
      }
      form{
        font-size: 20px;
        max-width: 715px;
        margin-left: auto;
      }
      .split{
        justify-content: space-between;
        align-items: center;
        .side{
          flex: 1;
        }
      }
    }
  }
}