.hero-block{
  background-color: $gray;
  text-align: center;
  padding: 18px 0;
  text-transform: uppercase;
  h1{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .breadcrumbs{
    font-size: 10px;
  }
  @include tablet-up{
    padding: 80px 0;
    h1{
      font-size: 40px;
      margin-bottom: 15px;
    }
    p{
      font-size: 18px;
    }
  }
}