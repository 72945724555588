.product-details-block{
  .container{
    >.split{
      padding: 40px 0 20px;    
      .side{
        .slider-featured-image{
          .featured-image{
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            object-fit: cover;
          }
        }
        .product-title{
          font-size: 12px;
          font-weight: 300;
          color: #6F6F6F;
          text-align: center;
          margin: 20px 0;
        }
        .slider-product-images{
          padding: 0 50px;
          position: relative;
          .slider-nav{
            position: absolute;
            top: calc(50% - 15px);
            z-index: 1;
            &.slider-nav-prev{
              left: 0;
            }
            &.slider-nav-next{
              right: 0;
            }
          }
          img{
            cursor: pointer;
            border: solid 1px $light-gray;
            transition: all .4s;
            opacity: 0;
            &.swiper-slide-thumb-active{
              border-color: $orange;
            }
            &.swiper-slide-fully-visible,
            &.swiper-slide-active{
              opacity: 1;
            }
          }
        }
        .specifications{
          padding: 20px 0;
          p{
            font-size: 12px;
            line-height: 1.4;
            b{
              font-weight: 500;
            }
          }
          li{
            font-size: 13px;
            line-height: 1.4;
            margin-bottom: 10px;
            &:last-child{
              margin-bottom: 0;
            }
          }
          h2{
            font-size: 18px;
            line-height: 1.2;
            margin: 15px 0;
            font-weight: 500;
          }
          .stars{
            margin-bottom: 15px;
          }
          ul{
            padding-left: 16px;
          }
        }
        .options{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 10px;
          padding: 20px 0;
          border-top: solid 1px $gray;
          border-bottom: solid 1px $gray;
          .product-option{
            padding: 5px;
            font-size: 12px;
            font-weight: 300;
            color: #6F6F6F;
            background-color: $white;
            border: solid 1px transparent;
            transition: all .4s;
            font-weight: 200;
            &.active{
              border-color: $orange;
              color: $orange;
              font-weight: 400;
            }
          }
        }
        .details-footer{
          padding: 20px;
          background-color: $white;
          box-shadow: -4px 0 4px 4px hsla(0, 0%, 0%, 0.05);
          position: fixed;
          bottom: -20px;
          transition: all .4s;
          left: 0;
          width: 100%;
          z-index: 99;
          opacity: 0;
          &.active{
            bottom: 0;
            opacity: 1;
          }
          .split{
            gap: 10px;
            flex-direction: row;
            flex-wrap: wrap;
            .side{
              width: 31%;
              &:first-child{
                width: 100%;
              }
            }
          }
          span{
            font-size: 11px;
            display: block;
            margin-bottom: 10px;
          }
          .price{
            display: flex;
            gap: 10px;
            align-items: flex-end;
            .display{
              font-size: 32px;
              font-size: 18px;
              font-weight: 500;
            }
            .crossed{
              font-size: 24px;
              font-size: 16px;
              font-weight: 300;
              text-decoration: line-through;
              color: $gray;
            }
          }
          .counter{
            display: flex;
            button,.qty{
              background-color: $white;
              width: 30px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0;
              border: solid 1px #f0f0f0;
              font-size: 20px;
            }
            .qty{
              font-size: 30px;
              border-right: none;
              border-left: none;
              font-size: 14px;
            }
            button{
              @include hover-focus{
                background-color: $white-hover;
              }
            }
          }
          .side{
            >.btn{
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  @include tablet-up{
    .container{
      >.split{
        padding: 100px 0;
        gap: 30px;
        >.side{
          &:first-child{
            .split{
              padding: 0;
              gap: 10px;
              flex-direction: row-reverse;
              .side{
                flex: 1;
                &:last-child{
                  max-width: 155px;
                }
              }
            }
            flex: 3;
            display: flex;
            flex-wrap: wrap;
            .slider-featured-image{
              flex: 1;
              order: 1;
            }
            .product-title{
              font-size: 15px;
              text-align: left;
              margin: 20px 0 0;
            }
            .slider-product-images{
              order: 2;
              flex: 1;
              max-width: 155px;
              width: 100%;
              padding: 0;
              img{
                width: 155px;
                height: 155px !important;
              }
              .slider-nav{
                display: none;
              }
            }
          }
          &:last-child{
            flex: 2.5;
          }
          .specifications{
            padding: 0 0 30px;
            p{
              font-size: 18px;
            }
            h2{
              font-size: 32px;
              margin: 20px 0;
            }
            li{
              font-size: 16px;
            }
            .stars{
              margin-bottom: 30px;
            }
          }
          .options{
            padding: 30px 0;
            grid-template-columns: 1fr 1fr 1fr;
            .product-option{
              font-size: 18px;
              padding: 20px;
            }
          }
          .details-footer{
            padding-top: 0;
            opacity: 1;
            position: unset;
            box-shadow: none;
            padding: 0;
            .split{
              padding: 30px 0 0;
              gap: 30px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              span{
                text-align: left;
                font-size: 16px;
              }
              .price{
                justify-content: flex-start;                
                .display{
                  font-size: 32px;
                }
                .crossed{
                  font-size: 24px;
                }
              }
              .side{
                width: unset;
              }
              .counter{
                margin-left: auto;
                .counter-btn,
                .qty{
                  width: 60px;
                  height: 60px;
                  padding: 0;
                }
                .counter-btn{
                  font-size: 24px;
                }
                .qty{
                  font-size: 32px;
                }
              }
              .btn{
                max-width: max-content;
                &.btn-secondary{
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}