.tabs-block{
  padding: 0 0 30px;
  .tab-buttons{
    border-bottom: solid 1px $gray;
    display: flex;
    justify-content: center;
    button{
      position: relative;
      flex: 1;
      padding: 10px;
      border: none;
      background-color: transparent;
      border-radius: 0;
      font-weight: 500;
      font-size: 12px;
      &::after{
        content: "";
        display: block;
        width: 0;
        height: 1px;
        background-color: $orange;
        position: absolute;
        bottom: -1px;
        transition: all .4s;
        left: 0;
        opacity: 0;
      }
      &.active{
        &::after{
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
  .tab-content{
    padding: 20px 0 0;
    display: none;
    p{
      font-size: 12px;
      line-height: 1.2;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      a{
        color: $orange;
      }
    }
    &.active{
      display: block;
    }
  }
  @include tablet-up{
    padding-bottom: 130px;
    .tab-buttons{
      button{
        font-size: 22px;
        padding: 20px;
      }
    }
    .tab-content{
      padding-top: 30px;
      p{
        font-size: 18px;
      }
    }
  }
}