@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "font";
@import "header";
@import "footer";

// blocks
@import "./Blocks/hero";
@import "./Blocks/details";
@import "./Blocks/tabs";
@import "./Blocks/related";
@import "./Blocks/newsletter";

html {
	background: $white;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Inter';
	font-size: 16px;
	line-height: 1;
 
	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
}

.container{
	@include tablet-up{
		padding: 0 50px;
	}
}

h1,h2,h3,h4,h5,p,ul,ol{
	margin: 0;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

.split{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	@include tablet-up{
		flex-direction: row;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.divider{
	width: 2px;
	height: 16px;
	display: block;
	background-color: $white;
}

.social{
	display: flex;
	gap: 20px;
	font-size: 20px;
}

.slider-nav{
	background-color: $orange;
	border: solid 1px $orange;
	font-size: 16px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $white;
	&.swiper-button-disabled{
		border-color: $gray;
		background-color: $gray;
	}
}

.stars{
	font-size: 14px;
	color: $lighter-gray;
	&.stars-5{
		color: $orange;
	}
	&.stars-4{
		i{
			&:nth-child(-n + 4){
				color: $orange;
			}
		}
	}
	&.stars-3{
		i{
			&:nth-child(-n + 3){
				color: $orange;
			}
		}
	}
	&.stars-2{
		i{
			&:nth-child(-n + 2){
				color: $orange;
			}
		}
	}
	&.stars-1{
		i{
			&:nth-child(-n + 1){
				color: $orange;
			}
		}
	}
	@include tablet-up{
		font-size: 30px;
	}
}

.btn{
	font-size: 14px;
	font-weight: 500;
	border: solid 1px transparent;
	background-color: transparent;
	padding: 12px 10px;
	display: block;	
	max-width: max-content;
	text-align: center;
	&.btn-primary{
		background-color: $orange;
		border-color: $orange;
		color: $white;
		@include hover-focus{
			background-color: $orange-hover;
		}
	}
	&.btn-secondary{
		background-color: $white;
		border-color: $white;
		color: $orange;
		border-color: $orange;
		@include hover-focus{
			background-color: $white-hover;
		}
	}
	&.btn-cart{
		color: #6f6f6f;
		font-size: 18px;
		display: flex;
		gap: 15px;
		padding: 20px 10px;
		justify-content: center;
		background-color: $white;
		&::before{
			content: "";
			font-family: Font Awesome\ 6 Pro;
			font-size: 16px;
		}
		@include hover-focus{
			background-color: $white-hover;
		}
	}
	@include tablet-up{
		font-size: 18px;
		padding: 20px 30px;
		min-width: 200px;
	}
}

.fav{
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	border-radius: 50%;
	color: #d7d7d7;
	border: solid 1px #d7d7d7;
	i{
		transform: scale(0.8);
		transition: all .4s;
	}
	&.active{
		color: #E73C17;
		i{
			transform: scale(1.1);
		}
	}
}

.swiper{
	.swiper-pagination{
		bottom: 0;
		z-index: 9;
		.swiper-pagination-bullet{
			width: 14px;
			height: 14px;
			transition: all .4s;
			&.swiper-pagination-bullet-active{
				background-color: #E73C17;
			}
		}
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		object-fit: cover;
		object-position: left;
		z-index: -1;
	}
}