$fa-font-path: "../font";
$html-font-size: 16;
$white: #ffffff;
$white-hover: #F3F3F3;
$black: #191919;
$gray: #D9D9D9;
$lighter-gray: #F1F1F1;
$light-gray: #F4F5F8;
$dark-gray: #2F333A;
$orange: #E73C17;
$orange-hover: #C22900;